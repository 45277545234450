const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.ozminerals.forwoodsafety.com',
    USER_TOKEN_URL: 'https://ylidoden84.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://h8kn446824.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.2',
    HOSTNAME: 'https://teams.staging.ozminerals.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.staging.ozminerals.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.staging.ozminerals.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.staging.ozminerals.forwoodsafety.com',
    WEBSOCKET: 'wss://hea8dzwrh2.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
